import Vue from "vue";
import VueRouter from 'vue-router'
// import  Login  from '../views/Login.vue'
// import  Admin  from '../views/Admin.vue'
// import  AddArticle  from '../components/article/AddArticle.vue'
// import  ArticleList  from '../components/article/ArticleList.vue'

// import  CateList  from '../components/CateList.vue'
// import  ArticleList  from '../components/ArticleList.vue'
// import ArticleListAll  from '../components/ArticleListAll.vue'
// import  ArticleDetail  from '../components/ArticleDetail'


// import  AddUser  from '../components/user/AddUser.vue'
// import  UserList  from '../components/user/UserList.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // component: ArticleListAll,
    component: () => import('../components/ArticleListAll.vue'),
    name:"ArticleListAll",
    hidden: true,
    meta: { title: '文章列表' },
    
  },
  {
    path: '/category',
    // component: CateList,
    component: () => import('../components/CateList.vue'),
    name:"CateList",
    hidden: true,
    meta: { title: '分类信息' },
    
  },

    {
          path: "/category/cat",
          name: "ArticleList",
          hidden: true,
          // component:   ArticleList,
          component: () => import('../components/ArticleList.vue'),
          meta: { title: "分类下文章列表" }
        
    },
    {
      path: "/article",
      name: "ArticleDetail",
      hidden: true,
      // component: ArticleDetail,
      component: () => import('../components/ArticleDetail.vue'),
      meta: { title: "文章详情" }
    
},
  
    // {
    //   path: "/articles/:cid",
    //   component: ArticleList,
    //   name: "ArticleList",
    //   props: true,
    //   meta: { title: "文章列表" }
    // },
]

const router = new VueRouter(
    {
      mode:"history",
      routes
    }
)

// router.beforeEach((to,from,next)=>{
//   const token = window.sessionStorage.getItem('token')
//   if(to.path =='/login')  return next()
//   if(!token){
//     next('/login')
//   }else{
//     next()
//   }
// })

export default router

























// import Vue from 'vue'
// import VueRouter from 'vue-router'

// // const ArticleList = () =>
// //   import(/* webpackChunkName: "group-index" */ '../components/ArticleList.vue')
// // const Detail = () =>
// //   import(/* webpackChunkName: "group-detail" */ '../components/Details.vue')
// // const Category = () =>
// //   import(/* webpackChunkName: "group-category" */ '../components/CateList.vue')

//   import  CateList  from '../components/CateList.vue'
// // const Search = () =>
// //   import(/* webpackChunkName: "group-search" */ '../components/Search.vue')

// Vue.use(VueRouter)

// // //获取原型对象上的push函数
// // const originalPush = VueRouter.prototype.push
// // //修改原型对象中的push方法
// // VueRouter.prototype.push = function push(location) {
// //   return originalPush.call(this, location).catch(err => err)
// // }

// const routes = [
// //   { path: '/', component: ArticleList, meta: { title: '欢迎来到GinBlog' } },
// //   {
// //     path: '/article/detail/:id',
// //     component: Detail,
// //     meta: { title: window.sessionStorage.getItem('title') },
// //     props: true
// //   },

//   {
//     path: '/getArtListByCat/:cid',
//     component: CateList,
//     meta: { title: '分类信息' },
//     props: true
//   },
// //   {
// //     path: '/search/:title',
// //     component: Search,
// //     meta: { title: '搜索结果' },
// //     props: true
// //   }
// ]

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

// router.beforeEach((to, from, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title ? to.meta.title : '加载中'
//   }
//   next()
// })

// export default router
