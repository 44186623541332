<template>
<div>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>
    
    <el-main>
      <router-view :key="$route.path"></router-view>
    </el-main>
    <Footer></Footer>
  </el-container>

  <el-row>
    <el-col :span="24"> <div class="footer_logo">yufeiguo</div></el-col>
    <el-col :span="24">
        <div class="footer_copyright">
        版权所有 © &nbsp;
        <el-link href="https://www.yufeiguo.com/" target="_blank" type="primary">
          鱼飞过
        </el-link>
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          class="icp-link"
        >
        浙ICP备2021007858号-2</a
        >
      </div>
    </el-col>
  </el-row>

</div>
    <!-- <v-app app>
      <TopBar></TopBar>
  
      <v-main class="grey lighten-3">
        <v-container>
          <v-row>
            <v-col cols="12" md="3">
              <Nav></Nav>
            </v-col>
            <v-col cols="12" md="9">
              <v-sheet min-height="80vh" rounded="lg">
                <router-view :key="$route.path"></router-view>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
  
      <Footer></Footer>
    </v-app> -->
  </template>
  
  <script>
  // import TopBar from '../components/TopBar'
  import Footer from '../components/Footer'
  import Header from '../components/Header'
//   import Nav from '../components/Nav'
  export default {
    components: { Header, Footer }
    // components: { TopBar, Footer, Nav }
  }
  </script>

<style scoped>
.el-main {
  text-align: center;
  padding-top: 36px;
  /* padding-top: 80px; */
}

.footer_logo {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center; /* 添加垂直居中 */
  margin-top: 10px; /* 进一步确保内容水平居中 */

  font-family: HyliaSerif;
  text-decoration: none;
  font-size: 1.5rem;
  color: #32373e;
}

.footer_copyright {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center; /* 添加垂直居中 */
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #999;
  text-align: center;
}

.footer_traffic {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center; /* 添加垂直居中 */
  /* margin-top: 10px; */
  margin-bottom: 10px;
  font-size: 20px;
  color: #6067f4;
  text-align: center;
}


/* .icp-link {
  display: inline-block;
  margin-left: 10px;
  text-decoration: none;
  color: inherit; 

} */

.icp-link {
  display: inline-block;
  margin-left: 10px;
  text-decoration: none;
  color: black; /* 设置链接颜色为黑色 */
  font-weight: bold; /* 设置文本为加粗 */
  /* 其他样式 */
}


  
    </style>


  