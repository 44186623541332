<template>
    <div class="header" style="top:0;left:0; right:0;">

        <el-menu mode="horizontal"  
        background-color="#80ccea" 
        text-color="#ffffff" 
        active-text-color="#251706"
        :default-active="activeIndex"
        router>
            <el-menu-item>
            <el-avatar :size="40" >
                <img src="../assets/logo.png" alt />
            </el-avatar> 
            </el-menu-item >
            <el-menu-item
                v-for="(item, key) in navOptions"
                :key="key"
                :index="item.index"
                style="background-color: unset"
                >
                {{ item.label }}
            </el-menu-item>
            <el-menu-item 
                key="about"
                index="/about"
                style="position: absolute;
                    bottom: 0;
                    right: 10px;"
            >
                    关于
            </el-menu-item>
        </el-menu>

    </div>


  </template>
  
  <script>
    export default {
      name: "HeadContainer",
      data() {
        return {
          Catlist: [],
          activeId:'',
          navOptions: [
            { label: "首页", index: "/" },
            { label: "分类", index: "/category" },
            { label: "标签", index: "/tag" }
            
        ],
        activeIndex: "this.$router.path"
  
        }
        
       
      },
      created() {
        this.getCatList()
      },
      methods: {   
        async getCatList(){
         const {data:res} = await this.$http.post("getCategoryList",{
                  pagesize:0,
                  pagenum:5
          })
          if(res.status != 200) return this.$message.error(res.message);
          this.Catlist =res.data
          // console.log(this.Catlist)
          // this.pagination.total = res.total
         },
    },
    };
    </script>
    <style scoped>

.el-menu {
  background-color: unset;
}
.el-menu--horizontal > .el-menu-item:hover {
  color: #fff;
}

  /* .header-container ul {
    float: right;
  }
    .el-menu.el-menu--horizontal {
    border-bottom: unset;
  }
  .el-menu {
    background-color: unset;
  }
  .el-menu--horizontal > .el-menu-item:hover {
    color: #fff;
  } */
  
 </style>
  