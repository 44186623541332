<template>
  <Home></Home>
</template>

<script>
import Home from './views/Home'
export default {
  components: { Home }
}
</script>
