import Vue from 'vue'
import axios from 'axios'

// axios请求地址
// axios.defaults.baseURL = 'http://localhost:3000/api/v1'

let host = window.location.host // 主机
let reg = /^localhost+/
if (reg.test(host)) {
  // 若本地项目调试使用
  axios.defaults.baseURL = 'http://localhost:3000/api/v1'
} else {
  // 远程访问 使用代理
  axios.defaults.baseURL = '/api/v1'
}

Vue.prototype.$http = axios
