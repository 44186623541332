import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
// import ElementUI, { Main } from 'element-ui'
import Highlight from "./utils/mhighlight";
import 'element-ui/lib/theme-chalk/index.css'
import './plugins/http'
import "viewerjs/dist/viewer.css"; 
import Viewer from "v-viewer";
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import "@/icons"; // icon
import { Button, Input, Pagination, Table, TableColumn, MessageBox,
  Container,Header,Main,Menu,MenuItem,Avatar,Tag } from 'element-ui';

import moment from 'moment'
Vue.filter('dateFormat', function (dateStr,pattern = "YYYY-MM-DD HH:mm:ss") {
  return moment(dateStr).format(pattern);
})
 


// Vue.use(ElementUI)
Vue.use(Button)
Vue.use(Input)
Vue.use(Pagination)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(MessageBox)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Avatar)
Vue.use(Tag)

Vue.use(Highlight)
Vue.use(mavonEditor)
Vue.use(Viewer);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
